import React from "react";
import LocalHeader from "../../shared/localHeader";

export default function LocalHeaderIndex() {
  return (
    <section id="localHeader">
      <LocalHeader titlesize="display-1" title={"Was \n hast du \ndavon?"} />
    </section>
  );
}
