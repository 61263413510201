import * as React from "react";
import DefautLayout from "../layouts/default";
import * as Home from "../components/screens/home";
import "../assets/sass/index.scss";

const IndexPage = () => {
  return (
    <DefautLayout title="Startseite">
      <div id="index">
        <Home.Header />
        <Home.Introduction />
        <Home.VideoMinister />
        <Home.LocalHeader />
        <Home.TargetGroup />
        <Home.Teaser />
        {/* <Home.QuoteDigitalMinister /> */}
        {/* <Home.QuotePresidentMinister /> */}
        <Home.Partners />
        {/* <Home.Mucl /> */}
        {/* <Home.Ecosystem /> */}
      </div>
    </DefautLayout>
  );
};

export default IndexPage;
