import React, { useState } from "react";
import { useRef } from "react";
import SharedVideo from "../../shared/video";
import videoUrl from "../../../assets/video/ViBo_Digitalschmiede_Fellows.mp4";
import digitalMinister from "../../../assets/img/components/video/ViBo_Digitalschmiede_Fellows_thumb.jpg";

export default function VideoMinister() {
  const videoRef = useRef(0.1);
  const [playing, setPlaying] = useState(true);

  const videoHandler = (control) => {
    if (control === true) {
      // var tracks = videoRef.current.textTracks[0];
      // tracks.mode = 'showing';
      videoRef.current.play();
    } else if (control === false) {
      videoRef.current.pause();
    }
  };

  return (
    <div className="grid p-grid-standard content xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 grid-center">
      <div className="col-12 lg:col-5">
        <SharedVideo
          video={videoUrl}
          videoRef={videoRef}
          poster={digitalMinister}
          buttontext="Video abspielen"
          buttonGridTemplateAreas={`'. icon text' 'vblue . .'`}
          buttonTextBackgroundColor="black"
          buttonTextColor="white"
          buttonIconColor="yellow"
          playing={playing}
          clickHandler={() => {
            setPlaying(!playing);
            videoHandler(playing);
          }}
        />
      </div>
      <div className="col-12 lg:col-7">
        <h3 className="tc-blue body-3 pb-30">“Ich freue mich, Ihnen die Digitalschmiede Bayern vorzustellen. Unser Programm, bei dem Sie die digitale Zukunft Bayerns persönlich an der Spitze mitgestalten können.”</h3>
        <p className="body-1">– Dr. Fabian Mehring - Bayerischer Staatsminister für Digitales</p>
      </div>
    </div>

  );
}
