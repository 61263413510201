import React from "react";
import { PixelsIntro } from "../../../assets/img/icons";
import ColumnText from "../../shared/columnText";

function Separator({ color = "black", height = 1 }) {
  return (
    <hr
      style={{
        backgroundColor: color,
        height: height,
        border: "none"
      }}
    />
  );
}
export default function Introduction() {
  return (
    <section className="home-intro" id="introduction" >
      <ColumnText
        nrColumns="2"
        textLeftStyle="body-1"
        textLeft={
          <>
            Die <strong>Digitalschmiede Bayern </strong> ist ein 3-monatiges Fellowship-Programm, in
            dem interdisziplinäre Teams befähigt werden
            <strong> digitale Produkte für bayerische Bürgerinnen und Bürger</strong> und
            die Verwaltung zu bauen.
            Nächster Start des inzwischen fünften Durchgangs ist der 05. August 2024.  <br />
            <img
              className="md:hidden sm:hidden xs:hidden pt-50"
              src={PixelsIntro}
              alt="pixels"
            ></img>
          </>
        }
        textRight={
          <>
            <div className="bc-blue p-30 lg:p-50">
            <h2 className="headline-2 pb-30">Unsere Kompetenzen auf einen Blick:</h2>
            <p className="py-20"><b>WIR</b> lösen echte Herausforderungen aus dem öffentlichen
            Sektor.</p>
            <Separator color="black" height="2px" />
            <p className="py-20">
            <b>WIR</b> bauen digitale Prototypen mit den neuesten Methoden und
              Werkzeugen.
            </p>
            <Separator color="black" height="2px" />
            <p className="py-20">
            <b>WIR</b> pflegen ein engagiertes Netzwerk.
            </p>
            <Separator color="black" height="2px" />
            <p className="py-20">
            <b>WIR</b> setzen dabei auf agile und nutzerzentrierte Ansätze und Methoden.
            </p>
            </div>
          </>
        }
      />
    </section>
  );
}
