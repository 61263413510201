import React from "react";
import Button from "./button";

export default function SharedHeader(props) {
  const {
    align,
    image,
    imageAltText,
    title,
    titlesize,
    text,
    textsize,
    buttontext,
    buttonGridTemplateAreas,
    buttonTextColor,
    buttonTextBackgroundColor,
    buttonIconColor,
    link,
    target,
    classNameImage,
    extraClassName,
    titlecolor,
    projectLogo,
    projectLink,
    ministryLogo,
    ministryLink,
    socialText
  } = props;
  const contrastAlign = align === "left" ? "right" : "left";
  const paddingMain =
    align === "left" ? "lg:pr-grid-standard" : "lg:pl-grid-standard";

  const paddingLarge =
    align === "left"
      ? "xs:py-standard md:pb-grid-standard"
      : "xs:py-standard md:pb-grid-standard";

  return (
    <header
      className={`header ${extraClassName ? extraClassName : null
        } content grid`}
    >
      
      <div className={`col-12 lg:col-6 header-${align} `}>
        <img
          className={`${classNameImage} img-h-500 `}
          src={image}
          alt={imageAltText}
        />
      </div>
      <div
        className={`col-12 lg:col-6 header-${contrastAlign} xs:p-grid-standard ${paddingMain}`}
      >
        <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 justify-end height-100 align-center">
          <div className="col-12">
            <p className={`tc-${titlecolor} ${titlesize} hyphens`}>{title}</p>
            <p className={`pt-30 ${textsize}`}>{text}</p>

            {socialText ? (
                <p className="pt-30 body-1">
                Aktuell arbeiten motivierte Fellows bis Ende Oktober an den Herausforderungen. Wenn ihr mehr über Teams und Themen in der Digitalschmiede erfahren wollt, schaut auf unserem <a target="_blank" href="https://www.linkedin.com/company/digitalschmiede-bayern/" className="link">LinkedIn</a> oder <a target="_blank" href="https://www.instagram.com/digital.schmiede.bayern/" className="link">Instagram-Kanal</a> vorbei.
                </p>
          ) : null}
          </div>


      

          {buttontext ? (
            <div className="col-12">
              <div className="header-button">
                <Button
                  text={buttontext}
                  gridTemplateAreas={buttonGridTemplateAreas}
                  textBackgroundColor={buttonTextBackgroundColor}
                  textColor={buttonTextColor}
                  iconColor={buttonIconColor}
                  link={link}
                  target={target}
                />
              </div>
            </div>
          ) : null}


          {ministryLogo && projectLogo? (
            <div className="col-11 pb-0 m-r-auto">
              <div className="md:grid grid-center grid-gap-0">
                <div className="md:col-4 hero-logo pb-20 md:pb-0">
                  <p class="pb-10 md:pb-20">
                    Ein Programm der:</p>
                  <div className="">
                    <a target="_blank" href={projectLink}>
                    <img
                      className=""
                      src={projectLogo}
                      alt="logo"
                    />
                  </a>
                  </div>
                </div>
                <div className="md:col-8 hero-logo">
                <p class="pb-10 md:pb-20 pb-20">
                  In Kooperation mit:</p>
                <div className="">
                  <a target="_blank" href={ministryLink}>
                  <img
                    className=""
                    src={ministryLogo}
                    alt="logo"
                  />
                  </a>
                </div>
              </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
}
