import React from "react";
import SharedHeader from "../../shared/header";
import header from "../../../assets/img/components/header/Home.webp";
import ministry from "../../../assets/img/components/brand/stmdLogo.svg";
import byte from "../../../assets/img/components/network/byte.webp";

export default function Header() {
  return (
    <section id="header">
      <SharedHeader
        image={header}
        classNameImage="header-image"
        imageAltText=""
        align="right"
        titlesize="display-1"
        titlecolor="blue"
        title="Hier"
        text="entwickeln Talente zusammen mit Behörden digitale Lösungen für ein zukunftsfähiges Bayern."
        textsize="body-3"
        ministryLogo={ministry}
        ministryLink="https://www.stmd.bayern.de/"
        projectLogo={byte}
        projectLink="https://byte.bayern/"
        socialText="true"
      />
    </section>
  );
}
