import React from "react";

export default function ColumnText(props) {
  let contentRight = <>{props.textRight}</>;
  let contentLeft = <>{props.textLeft}</>;

  let leftColumn = (
    <div className={`col-12 lg:col-6 body-1 `}>
      <div className="column-wrapper">
        {props.textMiddleTitle ? (
          <div className="pb-20 lg:pb-30 fw-semi-bold body-2">{props.textMiddleTitle}</div>
        ) : null}
        {props.listMiddle ? (
          <ul>
            <div>{props.textMiddle}</div>
          </ul>
        ) : (
          <div>{props.textMiddle}</div>
        )}
      </div>
    </div>
  );

  return (
 
    <div className={`grid p-grid-standard content ${
      props.columnStyle === "box" ? "boxstyle" : "xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100"
    } body-1`}>
      {props.bigTitle ? (
        <div className="col-12 headline-1 fw-semi-bold">{props.bigTitle}</div>
      ) : null}
      <div
        className={`col-12 lg:col-6 ${props.textLeftStyle}`}
      >
        <div className="column-wrapper">
        {props.textLeftTitle ? (
          <div className="pb-20 lg:pb-30 fw-semi-bold body-2">{props.textLeftTitle}</div>
        ) : null}
        {props.listLeft ? <ul>{contentLeft}</ul> : contentLeft}
      </div>
      </div>
      {props.nrColumns === "3" ? leftColumn : null}
      <div
        className={`col-12 lg:col-6 body-1`}
      >
        <div className="column-wrapper">
        {props.textRightTitle ? (
          <div className="pb-20 lg:pb-30 fw-semi-bold body-2">{props.textRightTitle}</div>
        ) : null}
        {props.listRight ? <ul>{contentRight}</ul> : contentRight}
      </div>
      </div>
    </div>
  );
}
