import React from "react";
import frauenhofer from "../../../assets/img/components/network/frauenhofer-logo.png";
import mps from "../../../assets/img/components/network/mps-logo.png";
import fim from "../../../assets/img/components/network/fim-logo.png";
import byte from "../../../assets/img/components/network/byte.webp";


const networkLogos = [
  {
    logo: byte,
    altText:
      "Logo der bayerischen Digitalagentur",
  },
  {
    logo: mps,
    altText: "",
  },
  {
    logo: frauenhofer,
    altText: "",
  },
  {
    logo: fim,
    altText:
      "",
  },
];
function ImageGrid(images) {
  return (
    <ul className="grid lg:grid-gap-50">
      {images.map((item, index) => (
        <li key={index} className="col-6 lg:col-3">
          <figure className="logo-wrapper">
            <img alt={item.altText} className="py-10" src={item.logo} />
          </figure>
        </li>
      ))}
    </ul>
  );
}
export default function Partners() {
  return (
    <section  id="partners">
      <div className="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 p-grid-standard body-1 content">
        <div className="col-12">
          <p className="pb-30 t-center">
            Die Herausforderungen der heutigen Zeit packt man am besten
            gemeinsam an. Deshalb bauen wir auf ein starkes Netzwerk:
          </p>
          {ImageGrid(networkLogos)}
        </div>
      </div>
    </section>
  );
}
