import React from "react";
import HorizontalTile from "../../shared/horizontalTile";
import LineBreak from "../../shared/lineBreak";

let groups = [
  {
    id: "talent",
    title: "Fellows",
    align: "right",
    lang: "en",
    excerpt:
      "Als Fellow lernst Du, schlank neue Prototypen zu bauen. Nach den besten Methoden aus Design Thinking, agilem Arbeiten und Lean Product Development.",
    buttonGridTemplateAreas: `'. . vyellow''. vblue icon'`,
    buttonIconColor: "black",
    link: "/program",
    divider: true,
    altText: "Mehr über das Programm erfahren"
  },
  {
    id: "verwaltung",
    title: "Ministerium",
    align: "right",
    lang: "de",
    excerpt:
      "Verwaltungen geben ihre Herausforderungen an interdisziplinäre Teams. Diese versuchen bürger- und nutzerzentriert neue Lösungswege zu validieren und als Prototyp zu demonstrieren.",
    buttonGridTemplateAreas: `'vblue . .''. vred icon'`,
    buttonIconColor: "black",
    link: "/authority",
    divider: true,
    altText: "Mehr über das Programm für Verwaltung erfahren"
  },
  {
    id: "gesellschaft",
    title: "Gesellschaft",
    align: "right",
    lang: "de",
    excerpt:
      "Wer profitiert am meisten? Unsere Gesellschaft. Neue Impulse zu setzen und neue Innovationen anzustoßen, wird unser aller Leben vereinfachen.",
    buttonGridTemplateAreas: `'vred . .' '. icon text'`,
    buttonText: "Jetzt mitmachen",
    buttonTextBackgroundColor: "black",
    buttonTextColor: "white",
    buttonIconColor: "yellow",
    link: "/program",
    altText: "Mehr über das Programm für Verwaltung erfahren"
  },
];

export default function TargetGroup() {
  return (
    <section id="targetGroup">
      {groups.map((group, index) => (
        <div key={index}>
          <div id={group.id} className="p-grid-standard content">
            <HorizontalTile
              title={group.title}
              lang={group.lang}
              align={group.align}
              excerpt={group.excerpt}
              buttonGridTemplateAreas={group.buttonGridTemplateAreas}
              buttontext={group.buttonText}
              buttonTextBackgroundColor={group.buttonTextBackgroundColor}
              buttonTextColor={group.buttonTextColor}
              buttonIconColor={group.buttonIconColor}
              link={group.link}
              altText={group.altText}
            />
          </div>
          {group.divider ? <LineBreak /> : <br />}
        </div>
      ))}
    </section>
    
  );
}
